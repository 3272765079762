import { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import googlePlay from "../../assets/home/google-play.png";
import appStore from "../../assets/home/app-store.png";

import paymentImage2 from "../../assets/payment/payment-2.png";
import paymentImage3 from "../../assets/payment/payment-3.png";
import cyparta from "../../assets/payment/cypartal logo 1.png";
import { colors } from "../../utils/theme";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { CategoriesState } from "../../types/categories";
import { Link, useNavigate } from "react-router-dom";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import {
  RequestGetCategories,
  setBread,
} from "../../store/products/productsSlice";
import { BiLogoFacebook } from 'react-icons/bi'

import { BsTiktok } from "react-icons/bs";
import { AiFillInstagram } from 'react-icons/ai'
import fawry from "../../assets/svg/336170.svg";

export const NavItem = styled("li")(({ theme }) => ({
  color: "rgba(27, 27, 27, 1)",
  marginBottom: "16px",
  fontWeight: "500",
  fontSize: "16px",
  display: "flex",
  alignItems: "center",
  gap: "7px",
  "&:hover": {
    color: colors.blue[200],
  },
  cursor: "pointer",
}));
const NavbarNav = styled("ul")({
  listStyle: "none",
  padding: "0",
  margin: "24px 0"
});
const Footer = () => {
  // -------- SELECTORS --------
  const token = useSelector((state: RootState) => state.user.user);
  const categories = useSelector((state: RootState) => state.products.cate);

  // -------- DISPATCH --------
  const dispatch = useDispatch<AppDispatch>();

  // -------- ROUTER --------
  const navigate = useNavigate();

  // -------- ONCE CHANGE --------
  useEffect(() => {
    dispatch(RequestGetCategories());
  }, []);

  return (
    <Box
      sx={{
        pt: "48px",
        borderTop: "0.5px solid #CFCFCF",
        mt: "48px",
      }}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={2.5}>
            <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
              {t("Categories")}
            </Typography>
            <NavbarNav>
              {categories
                .map((cate: CategoriesState, index: number) => {
                  return (
                    <NavItem
                      key={index}
                      onClick={() => {
                        navigate({
                          pathname: '/shop',
                          search: '?category_ids=' + cate?.id,
                        });
                        dispatch(setBread(cate.name));
                      }}
                    >
                      {cate.name}
                    </NavItem>
                  );
                })
                .slice(0, 3)}
            </NavbarNav>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.5}>
            <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
              {t("Account_polices")}
            </Typography>
            <NavbarNav>
              <NavItem>
                {token ? (
                  <Link
                    to="/profile"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {t("Account")}
                  </Link>
                ) : (
                  <span
                    onClick={() => {
                      dispatch(setOpenLogin(true));
                      dispatch(setSignType("login"));
                    }}
                  >
                    {t("Account")}
                  </span>
                )}
              </NavItem>
              <NavItem>
                {token ? (
                  <Link
                    to="/profile/order"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {t("Orders")}
                  </Link>
                ) : (
                  <span
                    onClick={() => {
                      dispatch(setOpenLogin(true));
                      dispatch(setSignType("login"));
                    }}
                  >
                    {t("Orders")}
                  </span>
                )}
              </NavItem>
              <NavItem>

                <Link
                  to="/profile/privacy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  {t("Privacy Policy")}
                </Link>

              </NavItem>
              <NavItem>

                <Link
                  to="/profile/refund&returnsPolicy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  {t("refund_privacy")}
                </Link>


              </NavItem>
              <NavItem>

                <Link
                  to="/profile/terms&condition"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  {t("terms_privacy")}
                </Link>

              </NavItem>
            </NavbarNav>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.5}>
            <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
              {t("Social Media")}
            </Typography>
            <NavbarNav>
              <Link
                to="https://www.facebook.com/rafalelectric/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <NavItem>
                  {/* <FacebookOutlinedIcon /> */}
                  <BiLogoFacebook style={{ fontSize: "21px" }} />
                  {t("Facebook")}
                </NavItem>
              </Link>
              <Link
                to="https://instagram.com/rafalelectric?igshid=YzgyMTM2MGM="
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <NavItem>
                  <AiFillInstagram style={{ fontSize: "21px" }} />
                  {t("Instagram")}
                </NavItem>
              </Link>
              <Link
                to="https://www.tiktok.com/@rafalelectric?_t=8bBbirfFdYs&_r=1"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <NavItem>
                  {/* <YouTubeIcon /> */}
                  <BsTiktok style={{ fontSize: "21px" }} />
                  {t("tiktok")}
                </NavItem>
              </Link>
            </NavbarNav>
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={4.5}>
            <Box sx={{ mb: "24px" }}>
              <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
                {t("We Accept")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  mt: "24px",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >


                <img src={paymentImage2} alt="payment" />
                <img src={paymentImage3} alt="payment" />
                <div style={{
          height: "60px",
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          
        }}>
        <img src={fawry} alt="fawry" style={{
          height: "100%",
          width: "100%",
          objectFit: "contain"
        }} />
        </div>
                {/* <img src={paymentImage4} alt="payment" /> */}
              </Box>
            </Box>
            {/* <Typography
              sx={{ fontWeight: "600", fontSize: "24px", mb: "20px" }}
            >
              {t("Be the first to know")}
            </Typography>
            <Typography
              sx={{ mb: "14px", fontWeight: "500", fontSize: "14px" }}
            >
              {t("Get notified of new offers and discounts")}
            </Typography>
            }
            {/* application links */}
            <Typography
              sx={{ mt: "24px", fontWeight: "500", fontSize: "14px" }}
            >
              {t("Download app")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: "16px",
                gap: "24px",
                flexWrap: "wrap",
              }}
            >
              <a href="https://play.google.com/store/apps/details?id=com.cyparta.rafal&hl=en_US" target="_blank" rel="noreferrer">
                <img src={googlePlay} alt="google play" />
              </a>
              <a href="https://apps.apple.com/us/app/rafal/id6473119898" target="_blank" rel="noreferrer">
                <img src={appStore} alt="app store" />
              </a>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: "35px",
            fontWeight: "400",
            pb: "32px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { md: "row", xs: "column" },
            alignItems: "center",
            gap: { md: 0, xs: 2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                height: "35px",
                display: "flex",
                alignItems: "center",
                pt: 1,
              }}
            >
              © {t("Copyright")}
            </Typography>
            <a href="https://cyparta.com/" target="_blank">
              <img src={cyparta} alt="cyparta" />
            </a>
          </Box>
          <Box>
            <span>{t("For inquiries")}</span>
            <a
              href="tel:+201010628457"
              style={{
                color: "rgba(14, 15, 29, 1)",
                fontWeight: "600",
                fontSize: "16px",
                textDecoration: "none",
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: "rgba(14, 15, 29, 1)",
                  fontWeight: "600",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {t('phone_en_ar')}
                {/* (+20) 1010628457 */}
              </Typography>
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
