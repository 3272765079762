import { Box, Radio, Typography } from '@mui/material'

import { t } from 'i18next'
import React from 'react'
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import fawry from '../../assets/svg/336170.svg'
import paymob from '../../assets/payment/payment-2.png'
function RadioGroupPayment() {

    const type = useSelector((state: RootState) => state.payment.type);

  const [selectedValue, setSelectedValue] = React.useState("a");
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    
    setSelectedValue(event.target.value);
    dispatch({type: "payment/setPaymentType", payload:event.target.value})
  };

  const controlProps = (item: string) => ({
    checked: type === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  
    console.log(type)
  return (
    <Box sx={{
        display:"flex",
        flexDirection:"row",
        gap: "20px", 
        pt:4
    }}>
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSelectedValue("fawry");
         
          // dispatch({type: "orders/setPaymentMethod", action:"cash on delivery"})
        }}
      >
        <Radio
          {...controlProps("fawry")}
          sx={{
            color: `rgba(245, 132, 31, 1)`,
            "&.Mui-checked": {
              color: `rgba(245, 132, 31, 1)`,
            },
          }}
          checkedIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
            >
              <g filter="url(#filter0_d_106_1238)">
                <path
                  d="M29 19C29 13.4792 24.5208 9 19 9C13.4792 9 9 13.4792 9 19C9 24.5208 13.4792 29 19 29C24.5208 29 29 24.5208 29 19Z"
                  fill="white"
                />
                <path
                  d="M29 19C29 13.4792 24.5208 9 19 9C13.4792 9 9 13.4792 9 19C9 24.5208 13.4792 29 19 29C24.5208 29 29 24.5208 29 19Z"
                  stroke="url(#paint0_linear_106_1238)"
                  stroke-miterlimit="10"
                />
              </g>
              <path
                d="M26.3337 19C26.3337 14.9514 23.0489 11.6667 19.0003 11.6667C14.9517 11.6667 11.667 14.9514 11.667 19C11.667 23.0486 14.9517 26.3333 19.0003 26.3333C23.0489 26.3333 26.3337 23.0486 26.3337 19Z"
                fill="url(#paint1_linear_106_1238)"
              />
              <defs>
                <filter
                  id="filter0_d_106_1238"
                  x="0.5"
                  y="0.5"
                  width="41"
                  height="41"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_106_1238"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_106_1238"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_106_1238"
                  x1="9"
                  y1="18.7778"
                  x2="28.999"
                  y2="18.9215"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F5841F" />
                  <stop offset="1" stopColor="#ED1C24" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_106_1238"
                  x1="11.667"
                  y1="18.837"
                  x2="26.3329"
                  y2="18.9424"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F5841F" />
                  <stop offset="1" stopColor="#ED1C24" />
                </linearGradient>
              </defs>
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <g filter="url(#filter0_d_106_1239)">
                <path
                  d="M28 18C28 12.4792 23.5208 8 18 8C12.4792 8 8 12.4792 8 18C8 23.5208 12.4792 28 18 28C23.5208 28 28 23.5208 28 18Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_106_1239"
                  x="0"
                  y="0"
                  width="40"
                  height="40"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_106_1239"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_106_1239"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          }
        />
        <div style={{
          height: "60px",
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          
        }}>
        <img src={fawry} alt="fawry" style={{
          height: "100%",
          width: "100%",
          objectFit: "contain"
        }} />
        </div>
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSelectedValue("paymob");
         
        }}
      >
        <Radio
          {...controlProps("paymob")}
          sx={{
            color: `rgba(245, 132, 31, 1)`,
            "&.Mui-checked": {
              color: `rgba(245, 132, 31, 1)`,
            },
          }}
          checkedIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
            >
              <g filter="url(#filter0_d_106_1238)">
                <path
                  d="M29 19C29 13.4792 24.5208 9 19 9C13.4792 9 9 13.4792 9 19C9 24.5208 13.4792 29 19 29C24.5208 29 29 24.5208 29 19Z"
                  fill="white"
                />
                <path
                  d="M29 19C29 13.4792 24.5208 9 19 9C13.4792 9 9 13.4792 9 19C9 24.5208 13.4792 29 19 29C24.5208 29 29 24.5208 29 19Z"
                  stroke="url(#paint0_linear_106_1238)"
                  stroke-miterlimit="10"
                />
              </g>
              <path
                d="M26.3337 19C26.3337 14.9514 23.0489 11.6667 19.0003 11.6667C14.9517 11.6667 11.667 14.9514 11.667 19C11.667 23.0486 14.9517 26.3333 19.0003 26.3333C23.0489 26.3333 26.3337 23.0486 26.3337 19Z"
                fill="url(#paint1_linear_106_1238)"
              />
              <defs>
                <filter
                  id="filter0_d_106_1238"
                  x="0.5"
                  y="0.5"
                  width="41"
                  height="41"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_106_1238"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_106_1238"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_106_1238"
                  x1="9"
                  y1="18.7778"
                  x2="28.999"
                  y2="18.9215"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F5841F" />
                  <stop offset="1" stopColor="#ED1C24" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_106_1238"
                  x1="11.667"
                  y1="18.837"
                  x2="26.3329"
                  y2="18.9424"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F5841F" />
                  <stop offset="1" stopColor="#ED1C24" />
                </linearGradient>
              </defs>
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <g filter="url(#filter0_d_106_1239)">
                <path
                  d="M28 18C28 12.4792 23.5208 8 18 8C12.4792 8 8 12.4792 8 18C8 23.5208 12.4792 28 18 28C23.5208 28 28 23.5208 28 18Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_106_1239"
                  x="0"
                  y="0"
                  width="40"
                  height="40"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_106_1239"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_106_1239"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          }
        />
        <div style={{
          height: "60px",
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          
        }}>
        <img src={paymob} alt="paymob" style={{
          height: "100%",
          width: "100%",
          objectFit: "contain"
        }} />
        </div>
      </Box>
    </Box>
  )
}

export default RadioGroupPayment