import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../data/apis";
import { setPaymentLoading } from "../globalSlice";

export interface PaymentState {
  value: number;
  loading: boolean;
  type?: string;
}

const initialState: PaymentState = {
  value: 0,
  loading: false,
  type: "fawry",
};

export const RequestPostPayment = createAsyncThunk(
  "RequestPostPayment",
  async (id: number, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token: any = state.user.user;
    const type: any = state.payment.type;

    try {
      const response = await axios.post(
        `${BASEURL}/payment/payment_checker/`,
        {
            pk: id,
            fawry: type === "fawry" ? true : false,
        },
        { headers: { Authorization: `Token ${token}` } }
      );
      ThunkApi.dispatch(setPaymentLoading(false));
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.data);
    }
  }
);

export const RequestPostGuestPayment = createAsyncThunk(
  "RequestPostGuestPayment",
  async (id: number, ThunkApi) => {
    try {
      const state:any= ThunkApi.getState();
      const type:any = state.payment.type; 
      const response = await axios.post(
        `${BASEURL}/payment/payment_checker/`,
        {
            pk: id,
            fawry: type === "fawry" ? true : false,
        }
      );
      ThunkApi.dispatch(setPaymentLoading(false));
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.data);
    }
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentType: (state, action) => {
      state.type = action.payload;
    },
  },
  extraReducers: (builder) => {
    // post Payment
    builder
      .addCase(RequestPostPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(RequestPostPayment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(RequestPostPayment.rejected, (state, action: any) => {
        state.loading = false;
      });
    // post Guest Payment
    builder
      .addCase(RequestPostGuestPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(RequestPostGuestPayment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(RequestPostGuestPayment.rejected, (state, action: any) => {
        state.loading = false;
      });
  },
});

// Action creators are generated for each case reducer function

// export const { increment, decrement, incrementByAmount } = paymentSlice.actions

export default paymentSlice.reducer;
