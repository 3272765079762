import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../data/apis";
import { toast } from "react-toastify";


export interface OrderState {
    loading: boolean;
    error: boolean;
    orders: any;
    errorMsg: any;
    order: any;
    payment_method: any,
    redirect: boolean
}

const initialState: OrderState = {
    loading: false,
    orders: [],
    order: {},
    error: false,
    errorMsg: "",
    payment_method: "Cash",
    redirect: false,
};

export const RequestGetOrders = createAsyncThunk(
    "RequestGetOrders",
    async (_, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const token: string = state.user.user;
        try {
            const res = await axios.get(`${BASEURL}/order/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            return res.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error.message);
        }
    }
);


export const RequestGetGuestOrders = createAsyncThunk(
    "RequestGetGuestOrders",
    async (_, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const cartID = state.guestCart.cartID;
        try {
            const res = await axios.get(`${BASEURL}/order/?session_key=${cartID}`);
            return res.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error.message);
        }
    }
);

export const RequestGetOrder = createAsyncThunk(
    "RequestGetOrder",
    async (id: string | undefined, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const token: string = state.user.user;
        try {
            const res = await axios.get(`${BASEURL}/order/${id}/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            return res.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error.message);
        }
    }
);

export const RequestGetGuestOrder = createAsyncThunk(
    "RequestGetGuestOrder",
    async (id: string | undefined, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const cartID = state.guestCart.cartID;
        try {
            const res = await axios.get(`${BASEURL}/order/${id}/?session_key=${cartID}`);
            return res.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error.message);
        }
    }
);

export const RequestPostOrder = createAsyncThunk(
    "RequestPostOrder",
    async (data: any, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const token: string = state.user.user;
        const payment_method: string = state.orders.payment_method;

        try {
            const res = await axios.post(`${BASEURL}/cart/checkout/`, { ...data, payment_method }, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            ThunkApi.dispatch({
                type: "cart/clearCart",
            });
            return res.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error);
        }
    }
);

export const RequestPostGuestOrder = createAsyncThunk(
    "RequestPostGuestOrder",
    async (data: any, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const payment_method: string = state.orders.payment_method;
        const cartID = state.guestCart.cartID;

        try {
            const res = await axios.post(`${BASEURL}/cart/checkout/?session_key=${cartID}`, { ...data, payment_method });
            ThunkApi.dispatch({ type: "guestCart/clearCart" })
            return res.data;
        } catch (error: any) {
            state.orders.redirect = false;
            return ThunkApi.rejectWithValue(error);
        }
    }
);


export const RequestUpdateOrder = createAsyncThunk(
    "RequestUpdateOrder",
    async (data: any, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const token: string = state.user.user;
        try {
            const res = await axios.patch(`${BASEURL}/order/${data?.order_id}/`, data, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            return res.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error.message);
        }
    }
);

export const RequestUpdateGuestOrder = createAsyncThunk(
    "RequestUpdateGuestOrder",
    async (data: any, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const cartID = state.guestCart.cartID;
        try {
            const res = await axios.patch(`${BASEURL}/order/${data?.order_id}/?session_key=${cartID}`, data);
            return res.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error.message);
        }
    }
);

export const RequestCanselOrder = createAsyncThunk(
    "RequestCanselOrder",
    async (id: string | undefined, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const token: string = state.user.user;
        try {
            const res = await axios.post(`${BASEURL}/order/cancel-order/`,
                {
                    order_id: id
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
            return res.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error.message);
        }
    }
);


export const RequestGuestCancelOrder = createAsyncThunk(
    "RequestGuestCancelOrder",
    async (id: string | undefined, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const cartID = state.guestCart.cartID;
        try {
            const res = await axios.post(`${BASEURL}/order/cancel-order/?session_key=${cartID}`,
                {
                    order_id: id
                });
            return res.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error.message);
        }
    }
);

export const orderSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        setPaymentMethod: (state, action) => {
            state.payment_method = action.payload
        }
    },
    extraReducers: (builder) => {
        // GET all Orders
        builder
            .addCase(RequestGetOrders.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestGetOrders.fulfilled, (state, action) => {
                state.loading = false;
                state.orders = action.payload;
            })
            .addCase(RequestGetOrders.rejected, (state, action) => {
                state.loading = true;
                state.errorMsg = action.payload
            });

        // get Order Details
        builder
            .addCase(RequestGetOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestGetOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.order = action.payload;
            })
            .addCase(RequestGetOrder.rejected, (state, action) => {
                state.loading = true;
                state.errorMsg = action.payload
            });

        // Post Order
        builder
            .addCase(RequestPostOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestPostOrder.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(RequestPostOrder.rejected, (state, action: any) => {
                state.loading = true;
                state.errorMsg = action.payload
                action?.payload?.response?.data?.map((ele: any) => toast.error(ele))
                // toast.error(action.payload.response.data[0])
            });

        // Post Guest Order
        builder
            .addCase(RequestPostGuestOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestPostGuestOrder.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(RequestPostGuestOrder.rejected, (state, action) => {
                state.loading = true;
                state.errorMsg = action.payload
            });

        // Update Order
        builder
            .addCase(RequestUpdateOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestUpdateOrder.fulfilled, (state, action) => {
                state.loading = false;

            })
            .addCase(RequestUpdateOrder.rejected, (state, action) => {
                state.loading = true;
            });

        // Cansel Order
        builder
            .addCase(RequestCanselOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestCanselOrder.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(RequestCanselOrder.rejected, (state, action) => {
                state.loading = true;
            });

        // GET Guest Orders
        builder
            .addCase(RequestGetGuestOrders.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestGetGuestOrders.fulfilled, (state, action) => {
                state.loading = false;
                state.orders = action.payload;
            })
            .addCase(RequestGetGuestOrders.rejected, (state, action) => {
                state.loading = true;
                state.errorMsg = action.payload
            });

        // GET Guest Order Details
        builder
            .addCase(RequestGetGuestOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestGetGuestOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.order = action.payload;
            })
            .addCase(RequestGetGuestOrder.rejected, (state, action) => {
                state.loading = true;
                state.errorMsg = action.payload
            });

        // Guest Cancel Order
        builder
            .addCase(RequestGuestCancelOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestGuestCancelOrder.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(RequestGuestCancelOrder.rejected, (state, action) => {
                state.loading = true;
            });
        // Update Guest Order
        builder
            .addCase(RequestUpdateGuestOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestUpdateGuestOrder.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(RequestUpdateGuestOrder.rejected, (state, action) => {
                state.loading = true;
            });
    },
});


export const { setPaymentMethod } = orderSlice.actions;

export default orderSlice.reducer;
